// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()

import 'bootstrap'
import '../scss/application.scss'
import $ from 'jquery'
import Dropzone from 'dropzone'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// This function is used to select all text of an input element once focussed
let focusedElement;
$(document).on('focus', '.fn-select', function () {
    if (focusedElement === this) return; //already focused, return so user can now place cursor at specific point in input.
    focusedElement = this;
    setTimeout(function () { focusedElement.select(); }, 50); //select all text in any field on focus for easy re-entry. Delay sightly to allow focus to "stick" before selecting.
});

// Activate dropzone
$(document).on("turbolinks:load", function(){
    $('#dropzone-container').each(() => {
        console.log('setting up dropzone')

        let uploadPath = $('#dropzone-container').data('upload-path')
        let csrfToken = $('meta[name="csrf-token"]').attr('content')
        let previewContainer = $('#dropzone-previews').get(0)
        let previewTemplate = $('#customer-upload-tmpl').html()

        console.log('uploadPath', uploadPath);
        console.log('token', csrfToken);
        new Dropzone('#dropzone-container', {
            url: uploadPath,
            headers: { 'X-CSRF-Token': csrfToken },
            paramName: "upload[file]", // The name that will be used to transfer the file
            maxFilesize: 512, // MB
            timeout: 180000, // 3 min.
            createImageThumbnails: false,
            previewsContainer: previewContainer,
            previewTemplate: previewTemplate,
            uploadprogress: (file, progressFloat) => {
                let progress = Math.round(progressFloat)
                let $bar = $(file.previewElement).find('.progress-bar')
                $bar.css({width: "" + progress + "%"})
                $bar.attr('aria-valuenow', progress)
                $bar.text("" + progress + "%")
            },
            success: (file, response) => {
                $(file.previewElement).replaceWith(response.content);
            },
            dictFallbackMessage: ''
        });
    });
});

$(document).on('change', '.custom-file-input', function(e){
    let fileName = document.getElementById("myInput").files[0].name;
    let nextSibling = e.target.nextElementSibling
    nextSibling.innerText = fileName
})
